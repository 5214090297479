/* eslint-disable complexity */
// @flow
import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useContext
} from 'react'
import { Grid } from '@material-ui/core'
import withApolloConsumer from 'components/shared/hocs/withApolloConsumer'
import { withStyles } from '@material-ui/core/styles'
import { get, isNaN, isEmpty, noop, flow } from 'lodash'
import useDimensions from 'react-cool-dimensions'
import { withRouter, Redirect } from 'react-router-dom'
import CustomerFormView from 'screens/customer/CustomerFormView'
import customerFormStyles from 'screens/customer/CustomerForm.styles'
import OptionalQuery from 'components/shared/OptionalQuery'
import ErrorComponent from 'components/shared/Error'
import withUnstatedContainer from 'components/shared/UnstatedContainerHOC'
import ScreenTitle from 'components/shared/ScreenTitle'
import withLaunchDarkly from 'components/shared/LaunchDarklyHOC'
import ErrorHandlingPage from 'components/shared/ErrorHandlingPage'
import NextButton from 'components/shared/NextButton'
import ReCaptcha from 'components/shared/ReCaptcha'
import CreditScoreNotice from 'components/shared/CreditScoreNotice'
import basePaths from 'constants/enums/basePaths'
import analyticsEventTypes, {
  eventTypes
} from 'constants/enums/analyticsEventTypes'
import errorTypes from 'constants/enums/errorTypes'
import deviceVerificationStatusTypes from 'constants/enums/deviceVerificationStatusTypes'
import featureFlags from 'constants/enums/featureFlags'
import { solarIntegratorPrequalificationEnabled } from 'util/FeatureHelper'
import permissionsEnum from 'constants/enums/permissions'
import { fieldsetNames } from 'constants/fieldsets/customerFieldsets'
import creditApplicationStatuses from 'constants/enums/creditApplicationStatuses'
import userTypesEnum from 'constants/enums/userTypes'
import { localizedTranslations } from 'properties/translations'
import {
  POLLING_INTERVAL,
  SHORT_TIMEOUT,
  EMPTY_STRING,
  DEFAULT_CUSTOMER_LOAN_TYPE
} from 'properties/properties'
import TranslationsContext from 'util/TranslationsContext'
import { reCaptchaActions, hideReCaptcha } from 'util/ReCaptchaHelper'
import { customerHasCreditApplication } from 'util/NavigationHelper'
import PrequalificationRouteContext from 'util/PrequalificationRouteContext'
import UserContext from 'util/UserContext'
import PrequalificationFormContext from 'util/PrequalificationFormContext'
import DisclosurePackageContext, {
  disclosurePackageContextValue
} from 'util/DisclosurePackageContext'
import {
  track,
  getSwiftlinksPartnerConversionPixel,
  swiftlinksLoadPartnerConversionPixelTagIfConfigured,
  swiftlinksTrackPartnerConversionPixelIfConfigured
} from 'util/AnalyticsHelper'
import type {
  UserAuthModel,
  PrequalificationFormProps,
  PrequalificationFormViewValues
} from 'util/TypesHelper'
import { getDeviceFingerprint } from 'util/DeviceHelper'
import Routes, { redirectToLoginWithAttemptedLink } from 'util/Routes'
import {
  storeUserTokens,
  isLoggedIn,
  destroyUserAccessToken,
  destroyUserSession,
  validateRefreshToken,
  isNewSession
} from 'util/SessionHelper'
import {
  getEnteredCustomer,
  isAggregatorCodePresent,
  isHostnameAllowed,
  getAllowDenyHostnamesConfig,
  postApplicationStatusMessageToParentWindowForCustomer,
  getDecisionStatusString,
  allHostnamesAllowed,
  getCustomerIdFromStorage,
  clearCustomerIdInStorage,
  getSwiftlinksReferrer,
  setAssignee,
  onAgreeAndContinue,
  onDeviceVerification,
  isSolarLoanType
} from 'util/PrequalificationHelper'
import { customerLanguageKey } from 'util/CustomerHelper'
import {
  formatCreditApplicationRequest,
  makeAddressRequest
} from 'util/ApplyHelper'
import { inIframe, isBasePath, isDevelopmentEnv } from 'util/EnvironmentHelper'
import { userHasPermission } from 'util/UserHelper'
import AccessModeContainer from 'store/AccessModeContainer'
import CustomerQuery from 'queries/BorrowerGetOwnCustomerQuery.graphql'
import CreateApplication from 'queries/CreateApplication.graphql'
import Layout from './PrequalificationForm/Layout'
import RedirectIfCreditApplication from './PrequalificationForm/RedirectIfCreditApplication'
import LoadingHandler from './PrequalificationForm/LoadingHandler'
import CustomerLoadedHandler from './PrequalificationForm/CustomerLoadedHandler'
import AgreeAndContinueForm from './PrequalificationForm/AgreeAndContinueForm'
import HeaderItems from './PrequalificationForm/HeaderItems'
import Alerts from './PrequalificationForm/Alerts'
import PrequalificationDisclosures from './PrequalificationForm/Disclosures'
import DeprecatedEndpointAlert from './PrequalificationForm/DeprecatedEndpointAlert'
import DisclosuresCheckbox from './PrequalificationForm/DisclosuresCheckbox'
import FormErrors from './PrequalificationForm/FormErrors'
import ApplicationError from './PrequalificationForm/ApplicationError'
import prequalificationFormStyles from './PrequalificationForm.styles'
import ConsentToContact from './queries/ConsentToContact.graphql'
import AddressVerificationDialog from './AddressVerificationDialog'
import TitleAttestationCheckbox from './PrequalificationForm/TitleAttestationCheckbox'

const PrequalificationForm = ({
  overrideHeader,
  match: { params },
  location: { search, pathname },
  accessModeContainer: {
    state: { accessMode }
  },
  classes,
  client,
  ldFlags,
  quote,
  isEstimateFlow,
  projects
}: PrequalificationFormProps) => {
  const { isSwiftlinks } = useContext(PrequalificationRouteContext)
  const { me, channelPartner } = useContext(UserContext)
  const { userType } = me

  const hostName = get(channelPartner, 'hostName', EMPTY_STRING)
  const defaultLoanType = get(
    channelPartner,
    'projectConfiguration.defaultLoanType',
    DEFAULT_CUSTOMER_LOAN_TYPE
  )

  const customerIdParam = get(params, 'customerId', null)
  const searchParams = useMemo(() => new URLSearchParams(search), [search])
  const requestId = searchParams.get('requestId')
  const enteredCustomerId = getEnteredCustomer()

  const swiftlinksPartnerFlagValue =
    ldFlags[featureFlags.swiftlinksPartnerConversionPixels]

  // In development, while logged out it misunderstands private routes as Swiftlinks. This variable is used to prevent that behavior.
  const isBasePathOnDevelopment =
    isDevelopmentEnv() && !isLoggedIn() && isBasePath(pathname)

  const [forceRerenderBit, setRerender] = useState(0)
  const toggleRerender = useCallback(
    () => setRerender(forceRerenderBit ? 0 : 1),
    [forceRerenderBit]
  )
  const [deviceFingerprint, setDeviceFingerprint] = useState(null)
  const [isPollingUser, setIsPollingUser] = useState(false)
  const [clickedContinue, setClickedContinue] = useState(false)
  const [creditApplicationStatus, setCreditApplicationStatus] = useState({
    submitting: false,
    error: null
  })

  const [lastPostedMessage, setLastPostedMessage] = useState({
    status: undefined,
    layoutHeight: undefined
  })
  const [contactConsent, setContactConsent] = useState(false)
  const [reCaptchaToken, setReCaptchaToken] = useState(null)
  const { status: lastPostedStatus, layoutHeight: lastPostedLayoutHeight } =
    lastPostedMessage

  const [customerId, setCustomerId] = useState(null)
  const [pageLoadTracked, setPageLoadTracked] = useState(false)
  const [userLoadedFromRequestId, setUserLoadedFromRequestId] = useState<
    boolean | Error
  >(false)
  const [deprecatedEndpointError, setDeprecatedEndpointError] =
    useState(EMPTY_STRING)
  const [formValid, setFormValid] = useState(false)
  const [formValidationInProgress, setFormValidationInProgress] =
    useState(false)
  const [disclosuresAreAccepted, setDisclosuresAreAccepted] = useState(false)
  const [titleAttestationAccepted, setTitleAttestationAccepted] =
    useState(false)
  const [formResult, setFormResult] = useState(null)
  const [submitButtonClicked, setSubmitButtonClicked] = useState(null)
  const [anyFormError, setAnyFormError] = useState(false)
  const [addressVerificationState, setAddressVerificationState] = useState({
    inProgress: false,
    verified: false,
    addresses: [],
    customer: {}
  })

  const { observe: layoutRef, height: layoutHeight } = useDimensions()

  const callConsentToContact = async (id = null, fingerprint = null) => {
    const result = await client.mutate({
      mutation: ConsentToContact,
      variables: {
        request: {
          customerId: id,
          url: window && window.location.href,
          deviceFingerprint: fingerprint || deviceFingerprint
        }
      }
    })

    if (result.data.consentToContact) {
      setContactConsent(true)
    }
  }

  const logoutUser = useCallback(
    (options = { rerender: true }) => {
      destroyUserSession(true)
      setCustomerId(null)
      setClickedContinue(false)
      clearCustomerIdInStorage()

      if (options.rerender) {
        setTimeout(toggleRerender, SHORT_TIMEOUT)
      }
    },
    [toggleRerender]
  )

  useEffect(() => {
    const setup = async () => {
      // Track Prequalification Swiftlinks Load only if user is not logged in as installer,
      // and after channelPartner loads.
      if (channelPartner && isSwiftlinks && !pageLoadTracked) {
        track(analyticsEventTypes.prequalificationLoad, {
          channelPartnerName: get(channelPartner, 'name'),
          channelPartnerHostname: hostName,
          swiftlinksReferrer: getSwiftlinksReferrer()
        })
        setPageLoadTracked(true)

        const swiftlinksPartnerPixelConfig =
          getSwiftlinksPartnerConversionPixel(
            swiftlinksPartnerFlagValue,
            hostName
          )
        swiftlinksLoadPartnerConversionPixelTagIfConfigured(
          swiftlinksPartnerPixelConfig
        )
      }
      // Hide the menu here.
      if (overrideHeader && !isBasePathOnDevelopment) {
        overrideHeader({ hidden: true })
      }
      // Load the device fingerprint.
      const fingerprint = await getDeviceFingerprint()
      setDeviceFingerprint(fingerprint)
      // If this user has a requestId (aka a refresh token) log em in,
      // but only once.
      if (!userLoadedFromRequestId && !isPollingUser) {
        // Load new user from requestId
        if (requestId && (!isLoggedIn() || isNewSession(requestId))) {
          setIsPollingUser(true)
          const loginResult: ?UserAuthModel = await validateRefreshToken(
            client,
            {
              refreshToken: requestId,
              deviceFingerprint: fingerprint,
              accessMode
            }
          )
          // Re-render false because setIsPollingUser and other hook setters
          // will cause a re-render anyway.
          logoutUser({ rerender: false })
          const { accessToken } = loginResult || {}
          if (accessToken) {
            storeUserTokens(accessToken, requestId)
            setUserLoadedFromRequestId(true)
          } else {
            setUserLoadedFromRequestId(
              new Error(errorTypes.creditApplicationExpired)
            )
          }
          setIsPollingUser(false)
        }
      }
    }

    setup()
  }, [
    setDeviceFingerprint,
    overrideHeader,
    toggleRerender,
    accessMode,
    client,
    search,
    requestId,
    logoutUser,
    channelPartner,
    isSwiftlinks,
    pageLoadTracked,
    userLoadedFromRequestId,
    isPollingUser,
    swiftlinksPartnerFlagValue,
    isBasePathOnDevelopment,
    hostName
  ])

  useEffect(
    function logoutUserIfSwiftlinksURLAndSessionCleared() {
      // See EX-5992 for more information
      if (
        !requestId &&
        !customerId &&
        isEmpty(enteredCustomerId) &&
        !pathname.includes(basePaths.apply)
      ) {
        destroyUserAccessToken()
      }
    },
    [requestId, pathname, customerId, enteredCustomerId]
  )

  useEffect(
    function setAssigneeFromSearchParams() {
      setAssignee(searchParams, ldFlags)
    },
    [searchParams, ldFlags]
  )

  if (isBasePathOnDevelopment) {
    return redirectToLoginWithAttemptedLink(pathname)
  }

  if (deprecatedEndpointError) {
    return (
      <DeprecatedEndpointAlert
        deprecatedEndpointError={deprecatedEndpointError}
      />
    )
  }

  const handleCreditApplicationStart = () => {
    setCreditApplicationStatus({
      submitting: true,
      error: null
    })
  }

  const handleCreditApplicationSuccess = startPolling => {
    startPolling(POLLING_INTERVAL)

    track(analyticsEventTypes.prequalificationSubmitApplication, {
      deviceFingerprint
    })

    // EX-6037
    const partnerConversionPixelConfig = getSwiftlinksPartnerConversionPixel(
      ldFlags,
      hostName
    )
    swiftlinksTrackPartnerConversionPixelIfConfigured(
      partnerConversionPixelConfig
    )
  }

  const handleCreditApplicationError = (e: Error) => {
    setCreditApplicationStatus({
      submitting: false,
      error: e
    })
  }

  const onSubmit =
    startPolling =>
    async (formValues: PrequalificationFormViewValues): Promise<boolean> => {
      const bundleId = get(channelPartner, 'bundleId', EMPTY_STRING)

      if (creditApplicationStatus.submitting) return false // Prevent duplicate submissions
      handleCreditApplicationStart()
      const creditApplicationRequest = formatCreditApplicationRequest(
        formValues.customer
      )
      try {
        const result = await client.mutate({
          mutation: CreateApplication,
          variables: {
            customerId: formValues.customerId,
            application: {
              ...creditApplicationRequest,
              // This button won't be clickable unless disclosures are accepted
              disclosureAccepted: true,
              disclosuresBundleId: bundleId
            }
          }
        })
        const applicationStatus = get(result, 'data.createApplication.status')
        if (applicationStatus) {
          handleCreditApplicationSuccess(startPolling)
          return true
        } else {
          handleCreditApplicationError(new Error())
          return false
        }
      } catch (e) {
        handleCreditApplicationError(e)
        return false
      }
    }

  // EX-5284 - Lock out certain partnerCodes
  const allowDenyHostnamesConfig = getAllowDenyHostnamesConfig(ldFlags)
  const shouldHostnameBeAllowed = allHostnamesAllowed(allowDenyHostnamesConfig)
    ? true
    : isHostnameAllowed(allowDenyHostnamesConfig, hostName)

  if (userLoadedFromRequestId && userLoadedFromRequestId instanceof Error) {
    return <ErrorHandlingPage errorType={userLoadedFromRequestId.message} />
  }

  if (!shouldHostnameBeAllowed && isSwiftlinks) {
    return <ErrorHandlingPage />
  }

  const currentCustomerId =
    customerIdParam || customerId || getCustomerIdFromStorage()

  if (
    userType === userTypesEnum.installer &&
    !userHasPermission(me, permissionsEnum.CREATE_CUSTOMER)
  ) {
    return <Redirect to={Routes.customerBlocked()} />
  }

  const handlePrequalificationUserInIframe = decisionStatus => {
    track(analyticsEventTypes.prequalificationUserInIFrame, {
      deviceFingerprint
    })
    postApplicationStatusMessageToParentWindowForCustomer(
      layoutHeight,
      decisionStatus
    )
    setLastPostedMessage({
      status: decisionStatus,
      layoutHeight
    })
  }

  const runCustomerQuery = () => {
    if (!isLoggedIn()) return false
    if (currentCustomerId) return true
    return requestId && !isNewSession(requestId)
  }
  const showLoadingIframe =
    inIframe() && isSwiftlinks && requestId && isNewSession(requestId)
  const customerVerified = contactConsent

  const toggleDisclosuresAcceptance = () => {
    setDisclosuresAreAccepted(!disclosuresAreAccepted)
    setSubmitButtonClicked(false)
  }
  const toggleTitleAttestationAccepted = () => {
    setTitleAttestationAccepted(!titleAttestationAccepted)
    setSubmitButtonClicked(false)
  }

  const setAndTrackAddresses = newAddresses => {
    track(eventTypes.prequalificationConfirmContinueClicked, {
      deviceFingerprint
    })
    setAddressVerificationState(status => ({
      ...status,
      verified: true,
      customer: {
        ...status.customer,
        addresses: newAddresses
      },
      addresses: newAddresses
    }))
  }

  const closeAddressVerification = () => {
    track(eventTypes.prequalificationConfirmBackClicked, {
      deviceFingerprint
    })
    setAddressVerificationState(status => ({
      ...status,
      inProgress: false,
      verified: false
    }))
  }

  const resetApplicationError = () =>
    setCreditApplicationStatus({
      ...creditApplicationStatus,
      error: null
    })

  const handleAddressVerificationEvents = formValues => {
    const addresses = get(formValues, 'customer.addresses', [])
    setAddressVerificationState(status => ({
      ...status,
      ...formValues,
      addresses,
      inProgress: true
    }))
  }

  return (
    <Layout
      ref={layoutRef}
      renderBackground={!isAggregatorCodePresent() && isSwiftlinks}
    >
      <OptionalQuery
        query={CustomerQuery}
        runQuery={runCustomerQuery}
        fetchPolicy="network-only"
        variables={{
          customerId: currentCustomerId
        }}
      >
        {({
          data: customerData,
          error: customerError,
          refetch: customerRefetch,
          loading: customerLoading,
          startPolling,
          stopPolling
        }) => {
          if (!channelPartner) {
            // Handle error
            return <ErrorHandlingPage userId={get(customerData, 'userId')} />
          }
          if (customerError && isSwiftlinks) return <ErrorComponent />

          const showLoadingNonIframe =
            customerLoading && isLoggedIn() && !clickedContinue

          if (showLoadingIframe || showLoadingNonIframe) {
            return (
              <LoadingHandler
                customerLoading={customerLoading}
                clickedContinue={clickedContinue}
                lastPostedStatus={lastPostedStatus}
                lastPostedLayoutHeight={lastPostedLayoutHeight}
                setLastPostedMessage={setLastPostedMessage}
              />
            )
          }

          const customer = get(customerData, 'customer', false)
          const creditApplication = get(customer, 'creditApplication')
          const customerLoanType = get(customer, 'loanType', defaultLoanType)
          const solarLoanType = isSolarLoanType({
            loanType: customerLoanType
          })
          const solarLoanTypeMode =
            solarLoanType && solarIntegratorPrequalificationEnabled(ldFlags)
          const titleAttestationNotRequiredOrAccepted = solarLoanTypeMode
            ? titleAttestationAccepted
            : true
          const addressVerificationDialogOpen =
            formValid &&
            submitButtonClicked &&
            addressVerificationState.inProgress &&
            !addressVerificationState.verified &&
            !creditApplicationStatus.submitting &&
            !creditApplicationStatus.error &&
            disclosuresAreAccepted &&
            titleAttestationNotRequiredOrAccepted
          const deviceVerificationStatus = get(
            customer,
            'deviceVerificationStatus'
          )
          const l10n = localizedTranslations(customerLanguageKey(customer))

          const providedDisclosureValue = disclosurePackageContextValue(
            customer,
            channelPartner
          )

          const decisionStatus = getDecisionStatusString(customer)

          const prequalificationUserInIFrame =
            !isNaN(layoutHeight) &&
            layoutHeight > 0 &&
            inIframe() &&
            (decisionStatus !== lastPostedStatus ||
              layoutHeight !== lastPostedLayoutHeight)

          if (prequalificationUserInIFrame) {
            handlePrequalificationUserInIframe(decisionStatus)
          }

          if (customerHasCreditApplication(customer)) {
            return (
              <RedirectIfCreditApplication
                customer={customer}
                isEstimateFlow={isEstimateFlow}
                quote={quote}
                stopPolling={stopPolling}
              />
            )
          }

          const customerPending =
            deviceVerificationStatus === deviceVerificationStatusTypes.pending
          const customerExpired =
            deviceVerificationStatus === deviceVerificationStatusTypes.expired

          if (customerExpired) {
            logoutUser()
          }

          // Side effects
          const customerIsPending = () => {
            startPolling(POLLING_INTERVAL)
            setIsPollingUser(true)
          }

          const customerWasVerified = () => {
            stopPolling()
            setIsPollingUser(false)
          }

          if (customerPending && !isPollingUser) {
            customerIsPending()
          }
          if (isPollingUser && customerVerified) {
            customerWasVerified()
          }

          const shouldDisplayReCaptcha =
            !isLoggedIn() &&
            get(creditApplication, 'status') !==
              creditApplicationStatuses.ready &&
            (!requestId || userLoadedFromRequestId)

          if (!shouldDisplayReCaptcha) {
            hideReCaptcha()
          }

          const sendFormValuesIfSafe = async formValues => {
            const reset = () => {
              setSubmitButtonClicked(false)
              setFormResult(null)
              setAddressVerificationState(state => ({
                ...state,
                inProgress: false,
                verified: false
              }))
            }

            if (
              formResult !== null ||
              !disclosuresAreAccepted ||
              !customer ||
              !customer.id
            ) {
              reset()
              return
            }
            const result = await onSubmit(startPolling)(formValues)
            setFormResult(result)
            if (!result) reset()
          }

          const handleSubmit = formValues => {
            setSubmitButtonClicked(true)

            if (disclosuresAreAccepted) {
              resetApplicationError()
              if (!addressVerificationState.verified) {
                handleAddressVerificationEvents(formValues)
              } else {
                sendFormValuesIfSafe(formValues)
              }
            }
          }

          return (
            <PrequalificationFormContext.Provider
              value={{
                customer,
                customerVerified,
                customerExpired,
                customerPending,
                customerLoading,
                customerLoanType
              }}
            >
              <TranslationsContext.Provider value={l10n}>
                <ScreenTitle title={l10n.screenTitles.prequalificationForm} />
                <DisclosurePackageContext.Provider
                  value={providedDisclosureValue}
                >
                  <HeaderItems />
                  <Alerts />
                  <Grid item>
                    <Grid container item justify="center">
                      <StyledCustomerFormView
                        customer={customer}
                        onSubmit={handleSubmit}
                        canEdit={
                          get(customer, 'id') &&
                          !formValidationInProgress &&
                          !creditApplicationStatus.submitting &&
                          customerVerified
                        }
                        onChange={() => {
                          if (addressVerificationState.inProgress) return
                          setSubmitButtonClicked(false)
                        }}
                        disableFields={
                          !customerVerified ||
                          formValidationInProgress ||
                          creditApplicationStatus.submitting
                        }
                        fieldset={fieldsetNames.FIELDSET_PUBLIC_APPLICATION}
                        isInfoEditable={
                          !customerVerified &&
                          !formValidationInProgress &&
                          !creditApplicationStatus.submitting
                        }
                        inactiveForm={!customerVerified}
                        setFormValid={setFormValid}
                        toggleValidationInProgress={() => {
                          setFormValidationInProgress(
                            currentState => !currentState
                          )
                        }}
                        isFormValid={formValid}
                        anyFormError={anyFormError}
                        setAnyFormError={setAnyFormError}
                      >
                        {(content, { submit, reset }) => (
                          <CustomerLoadedHandler
                            customer={customer}
                            reset={reset}
                          >
                            <AgreeAndContinueForm
                              deviceFingerprint={deviceFingerprint}
                              onDeviceVerificationStarted={() =>
                                setClickedContinue(true)
                              }
                              onDeviceVerificationFinished={userAuthModelResponse => {
                                onDeviceVerification(
                                  customer,
                                  userAuthModelResponse,
                                  {
                                    afterDeviceVerification: customerRefetch,
                                    deviceFingerprint
                                  }
                                )
                              }}
                              onDeviceVerificationError={() =>
                                setClickedContinue(false)
                              }
                              onAgreeAndContinue={() =>
                                onAgreeAndContinue({ customerRefetch })
                              }
                              currentCustomerId={currentCustomerId}
                              applicationSubmitting={
                                creditApplicationStatus.submitting
                              }
                              callConsentToContact={callConsentToContact}
                              clickedContinue={clickedContinue}
                              setContactConsent={setContactConsent}
                              setCustomerId={setCustomerId}
                              isEstimateFlow={isEstimateFlow}
                              projects={projects}
                              reCaptchaToken={reCaptchaToken}
                              setDeprecatedEndpointError={
                                setDeprecatedEndpointError
                              }
                              formValidationInProgress={
                                formValidationInProgress
                              }
                            />

                            {content}

                            {/* Address and info verification */}
                            <AddressVerificationDialog
                              open={addressVerificationDialogOpen}
                              onClose={closeAddressVerification}
                              onEntered={noop}
                              onSubmit={async newAddresses => {
                                // Submit the form with the revised addresses.
                                setAndTrackAddresses(newAddresses)
                                submit()
                              }}
                              customer={addressVerificationState.customer}
                              addresses={addressVerificationState.addresses.map(
                                makeAddressRequest
                              )}
                            />
                            <PrequalificationDisclosures
                              isInactive={!customerVerified}
                            />
                            <DisclosuresCheckbox
                              disclosuresAreAccepted={disclosuresAreAccepted}
                              toggleDisclosuresAcceptance={
                                toggleDisclosuresAcceptance
                              }
                              submitButtonClicked={submitButtonClicked}
                            />
                            {solarLoanTypeMode && (
                              <TitleAttestationCheckbox
                                titleAttestationAccepted={
                                  titleAttestationAccepted
                                }
                                toggleTitleAttestationAccepted={
                                  toggleTitleAttestationAccepted
                                }
                                submitButtonClicked={submitButtonClicked}
                              />
                            )}
                            <Grid
                              container
                              item
                              direction="column"
                              alignItems="center"
                            >
                              <NextButton
                                centerRipple
                                showsPleaseWait
                                className={classes.button}
                                submitting={creditApplicationStatus.submitting}
                                disabled={
                                  creditApplicationStatus.submitting ||
                                  addressVerificationDialogOpen ||
                                  !customerVerified
                                }
                                fieldset={
                                  fieldsetNames.FIELDSET_PUBLIC_APPLICATION
                                }
                              >
                                {l10n.prequalificationForm.submit}
                              </NextButton>
                              {creditApplicationStatus.error && (
                                <ApplicationError
                                  error={creditApplicationStatus.error}
                                />
                              )}
                              {anyFormError && <FormErrors />}
                              <CreditScoreNotice
                                isInactive={!customerVerified}
                              />
                            </Grid>
                          </CustomerLoadedHandler>
                        )}
                      </StyledCustomerFormView>
                    </Grid>
                  </Grid>
                  {shouldDisplayReCaptcha && (
                    <ReCaptcha
                      action={reCaptchaActions.prequalificationFormEntered}
                      setToken={token => setReCaptchaToken(token)}
                    />
                  )}
                </DisclosurePackageContext.Provider>
              </TranslationsContext.Provider>
            </PrequalificationFormContext.Provider>
          )
        }}
      </OptionalQuery>
    </Layout>
  )
}

const StyledCustomerFormView = withStyles(customerFormStyles)(
  withUnstatedContainer(CustomerFormView, {
    accessModeContainer: AccessModeContainer
  })
)

export default flow(
  withRouter,
  withLaunchDarkly,
  withApolloConsumer,
  withStyles(prequalificationFormStyles)
)(
  withUnstatedContainer(PrequalificationForm, {
    accessModeContainer: AccessModeContainer
  })
)
